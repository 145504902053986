import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import scrollTo from 'gatsby-plugin-smoothscroll';



const ProductCard = ({id, title, desc, thumbnail }) => {
    const [scroll, setScroll] = React.useState(false);
    const handleChange = () => {
        setScroll(true);
        // document.getElementById('navQuote').click();
    }
    if(scroll === true){
        scrollTo('#quote');
        setScroll(false);
    }
    return(
         <div className="d-flex justify-content-center" key={id}>
            <Card style={{ width: '15rem', minHeight: '405px' }} className="shadow-lg" >
                <Card.Img variant="top" src={thumbnail} height="180px"/>
                <Card.Body className="text-center">
                    <Card.Title className="color-primary font-weight-bold">{title}</Card.Title>
                    <Card.Text style={{fontSize: '13px'}}>
                        {desc}
                    </Card.Text>
                    <Button variant="primary" className="align-bottom custom-btn" onClick={handleChange} >Get Quotation</Button>
                </Card.Body>
            </Card>
        </div>
    )
}

export default ProductCard;