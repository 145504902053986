import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import scrollTo from 'gatsby-plugin-smoothscroll';

// import { Helmet } from 'react-helmet';

class Header extends Component{
  componentDidMount(){

  }

  render(){
    
    return(
      <>
        
        <Navbar collapseOnSelect expand="lg"  variant="light" className="py-0 shadow-lg" sticky="top" >
          <Container>

            <Navbar.Brand href="#home" className=" d-none d-lg-inline-block d-md-inline-block">
              <img
                alt=""
                src="https://lh3.googleusercontent.com/yGMK9bXKWtpk3jQmjS775yvwCqNNS9NjzTYKWziOJqUVkWBb7U_nr9UuVHa8tr3GEG1_7Ocf9xWg85A9bApC7KxqdfM4NQC4iJLngnIFC8TVAjj-n2HRKXfR1gKXzQYQKU8c36lE=w2400"
                width="220"
                
                className="align-top d-inline-block"
              />{' '}
              
            </Navbar.Brand>

            <Navbar.Brand href="#home" className="d-sm-inline-block d-md-none">
              <img
                alt=""
                src="https://lh3.googleusercontent.com/yGMK9bXKWtpk3jQmjS775yvwCqNNS9NjzTYKWziOJqUVkWBb7U_nr9UuVHa8tr3GEG1_7Ocf9xWg85A9bApC7KxqdfM4NQC4iJLngnIFC8TVAjj-n2HRKXfR1gKXzQYQKU8c36lE=w2400"
                width="210"
                className="align-top d-inline-block "
              />{' '}
              
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ml-auto text-center">
                <Nav.Link href="#" className="px-4" onClick={()=>scrollTo('#home')} >Home</Nav.Link>
                <Nav.Link href="#" className="px-4" onClick={()=>scrollTo('#about')}>About</Nav.Link>
                <Nav.Link href="#" className="px-4" onClick={()=>scrollTo('#products')}>Products</Nav.Link>
                <Nav.Link href="#" className="px-4" onClick={()=>scrollTo('#quote')}>Get Quotation</Nav.Link>
                <Nav.Link href="#" className="px-4" onClick={()=>scrollTo('#contact')}>Contact</Nav.Link>
              </Nav>
            </Navbar.Collapse>
            
          </Container>
        </Navbar>
      </>
    )
  }
}

export default Header;