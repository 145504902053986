import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhoneSquareAlt } from '@fortawesome/free-solid-svg-icons';
// import scrollTo from 'gatsby-plugin-smoothscroll';

class Footer extends React.Component{
    render(){
        const currentYear = new Date().getFullYear();
        return(
            <>
                <Container>
                    <Row>
                        <Col sm={6}>

                        </Col>

                        <Col sm={12}>
                            <Row >
                                <Col sm={12} className="pl-0 pr-0 mt-3 ml-0 mr-0 d-flex justify-content-center">
                                    <img 
                                        src="https://lh3.googleusercontent.com/vsxyQlkzAJFZyERXojVuFtmat_3g8KaQ-GBRYUZounFtUJU4kBOF3fz2YFI-dx0ESBbyzGoL8urV9bCRj2hhyZJXTibbxKXhG5Brv_sS22pqo_ZrfuOP9MTDFaozqQx3n1CmEu4T=w2400" 
                                        alt=""
                                        height="140"
                                        width="140"
                                        
                                        />
                                </Col>                        
                            </Row>
                            <Row className='mt-3'>
                                <Col sm={12} className="text-center">
                                    <a href="http://www.instagram.com/naqiyahpackaging" aria-label="instagram" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faInstagram} size="2x" className="mx-3 color-secondary" /></a>
                                    <a href="https://wa.me/971582615753" aria-label="whatsapp" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faWhatsapp} size="2x" className="mx-3 color-secondary" /></a>
                                    <a href="mailto:sales@naqiyahpack.com" aria-label="email" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faEnvelope} size="2x" className="mx-3 color-secondary" /></a>
                                    <a href="tel:+971582615753" aria-label="phone" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faPhoneSquareAlt} size="2x" className="mx-3 color-secondary" /></a>
                                    
                                </Col>
                                <Col sm={12} className="mt-3 text-center text-white small">
                                    13 StreetRas Al Khor Industrial Area 1 <br/> Dubai, United Arab Emirates<br/> +971582615753&nbsp; | +97143233112
                                </Col>
                            </Row>
                        </Col>

                        {/* <Col sm={12} className="mt-4 text-center text-white subhead small">
                            <p>
                                <a href="#" onClick={()=>scrollTo('#home')} >Home</a> | <a href="#" onClick={()=>scrollTo('#about')} >About Us</a> | <a href="#products" onClick={()=>scrollTo('#products')} >Products</a> | <a href="#" onClick={()=>scrollTo('#quote')} >Get Quotation</a> | <a href="#" onClick={()=>scrollTo('#home')} >Contact</a> | Sitemap
                            </p>
                        </Col> */}
                        
                    </Row>
                    <hr style={{'backgroundColor':'grey'}} />
                </Container>
                
                <div className="py-2 text-center text-white copy subhead">
                    Copyright &#169; {currentYear} | Naqiyah Pack LLC.
                </div>
            </>
        )
    }
}

export default Footer;
