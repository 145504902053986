import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import scrollTo from 'gatsby-plugin-smoothscroll';

class ImageCarousel extends Component {
    

    render(){
        
        const slides = this.props.slides.allMarkdownRemark.edges;
        

        return(
            <>
                <Carousel className="z2 d-none d-md-block" >

                    <Carousel.Item>
                        <div className="img">
                            <img
                                className="d-block w-100"
                                src="https://lh3.googleusercontent.com/vsJYbfu58sI2VtusglfwDgDI12kYyWUn-dnuQ7no28-V8GXbkWSlB260ZJkNBFCBXzcd7mh2du2egry8gdK92btCNqcTHPHbwHXxycb5s5KYI7gnTuhUEzKvJWHZau8kviEfHJ3kLq8=w2400"
                                alt="First slide"
                                style={{height: '90vh'}}
                                />
                        </div>
                           
                        <Carousel.Caption className="text-center center-caption-first">
                            <h3>Introducing</h3>
                            <img 
                                        src="https://lh3.googleusercontent.com/P6-cwG6zvSwP2Rrxs6B1LMryorSvHwtgckTdN4ni9dch15Esx-TRQWX9OtkhiAvtU-4RzrCL9xkuP8s3ER70jsawehE6iZXH-CbwxXia-Oz7yJxCWpuHgOfRt-rFUg2khSSiPpp5YA=w2400" 
                                        alt=""
                                        height="320"
                                        width="auto"
                                        className="mx-auto d-none d-md-block"
                            />
                        
                        </Carousel.Caption>
                    </Carousel.Item>


                    {slides.map(
                        ({
                            node: {
                                    id, frontmatter: { title, thumbnail, desc, subcategories, types, titletf, buttontf, desctf }
                                }
                        })=>{
                            let showTitle = "";
                            let showDesc = "";
                            let showButton = "";
                            if(titletf === true){
                                showTitle = <h1 className="mb-4 heading display-2" >{title}</h1>;
                            }else{
                                showTitle = "";
                            }
                            if(desctf === true){
                                showDesc = <p className="mb-2">{desc}</p>;
                            }else{
                                showDesc = "";
                            }
                            if(buttontf === true){
                                showButton = <Button variant="outline-light" size="lg" onClick={()=>scrollTo('#products')} > Go To Products </Button>;
                            }else{
                                showButton = "";
                            }
                            if(titletf === true || buttontf === true || desctf === true){
                                return(
                                    <Carousel.Item key={id}>
                                        <div className="img">
                                            <img
                                                className="d-block w-100"
                                                src={thumbnail}
                                                alt="Packaging Material"
                                                style={{height: '90vh'}}
                                                />
                                        </div>
                                        <Carousel.Caption className="center-caption">
                                            {showTitle}
                                            {showDesc}
                                            {showButton}
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                )
                            }
                            else{
                                return null;
                            }
                            
                        }
                    )}

                </Carousel>
               
            {/* Show Only On Mobile */}

                <Carousel className="z2 d-sm-block d-md-none">

                    <Carousel.Item>
                        <div className="img">
                            <img
                                className="d-block"
                                src="https://lh3.googleusercontent.com/vsJYbfu58sI2VtusglfwDgDI12kYyWUn-dnuQ7no28-V8GXbkWSlB260ZJkNBFCBXzcd7mh2du2egry8gdK92btCNqcTHPHbwHXxycb5s5KYI7gnTuhUEzKvJWHZau8kviEfHJ3kLq8=w2400"
                                alt="First slide"
                                style={{height: '93vh'}}
                            />
                        </div>
                           
                        <Carousel.Caption className="center-caption-first">
                        <h4>Introducing</h4>
                        <img 
                                    src="https://lh3.googleusercontent.com/vsxyQlkzAJFZyERXojVuFtmat_3g8KaQ-GBRYUZounFtUJU4kBOF3fz2YFI-dx0ESBbyzGoL8urV9bCRj2hhyZJXTibbxKXhG5Brv_sS22pqo_ZrfuOP9MTDFaozqQx3n1CmEu4T=w2400" 
                                    alt=""
                                    height="250"
                                    width="250"
                                    className="mb-4 d-sm-block d-md-none"
                        />
                        </Carousel.Caption>
                    </Carousel.Item>


                    {slides.map(
                        ({
                            node: {
                                    id, frontmatter: { title, thumbnail, desc, subcategories, types, titletf, buttontf, desctf }
                                }
                        })=>{
                            let showTitle = "";
                            let showDesc = "";
                            let showButton = "";
                            if(titletf === true){
                                showTitle = <h1 className="mb-4 heading" >{title}</h1>;
                            }else{
                                showTitle = "";
                            }
                            if(desctf === true){
                                showDesc = <p className="mb-2">{desc}</p>;
                            }else{
                                showDesc = "";
                            }
                            if(buttontf === true){
                                showButton = <Button variant="outline-light" size="lg" onClick={()=>scrollTo('#products')} > Go To Products </Button>;
                            }else{
                                showButton = "";
                            }
                            if(titletf === true || buttontf === true || desctf === true){
                                return(
                                    <Carousel.Item key={id}>
                                        <div className="img">
                                            <img
                                            className="d-block"
                                            src={thumbnail}
                                            alt="Third slide"
                                            style={{height: '93vh'}}
                                            />
                                        </div>
                                    

                                        <Carousel.Caption className="center-caption">
                                        {showTitle}
                                        {showDesc}
                                        {showButton}
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                )
                            }
                            else{
                                return null;
                            }
                            
                        }
                    )}


                </Carousel>
            </>
        )
    }
}

export default ImageCarousel;
