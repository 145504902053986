import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
// import scrollTo from 'gatsby-plugin-smoothscroll';

const ProductsWithPopup = ({id, title, thumbnail, desc, data}) => {
    
    const [show, setShow] = React.useState(false);
    // const [scroll, setScroll] = React.useState(false); 

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const handleChange = () => {
        handleClose();
    }

    return(
        <>
            <div className="d-flex justify-content-center" key={id}>
                <Card style={{ width: '15rem', minHeight: '405px', maxHeight: '405px' }} className="shadow-lg" >
                    <Card.Img variant="top" src={thumbnail} height="180px"/>
                    <Card.Body className="text-center">
                        <Card.Title className="color-primary font-weight-bold">{title}</Card.Title>
                        <Card.Text style={{fontSize: '13px'}}>
                            {desc}
                        </Card.Text>
                        <Button variant="primary" className="align-bottom custom-btn" onClick={handleShow} >Know More </Button>
                    </Card.Body>
                </Card>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                aria-labelledby="subproducts-modal"
                scrollable={true}
                style={{maxHeight: '90%'}}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="subproducts-modal">
                        All Types of&nbsp; {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        {
                            data.map(({subtitle, subdesc, subthumbnail})=>(
                                <Row className="mb-3" key={id+'-'+subtitle}>
                                    <Col xs={3}>
                                        <img src={subthumbnail} alt="product" height="80px" width="90px" />
                                    </Col>
                                    <Col xs={8} className="ml-3">
                                        <h4>{subtitle}</h4>
                                        <p>{subdesc}</p>  
                                    </Col>
                                    <hr/>
                                </Row>
                            ))
                        }
                        
                    </Container>
                </Modal.Body>
                <Modal.Footer className="text-center">
                    <Button variant="primary" href="https://naqiyahpack.com/#quote" className="align-bottom custom-btn" onClick={handleChange}>Get Quotation</Button>
                </Modal.Footer>
            </Modal>
        </>    
    )

}

export default ProductsWithPopup;