import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Slider from 'react-slick';
import ProductCard from './card';
import ProductWithPopup from './card-with-modal';


class Products extends React.Component{
    
    render(){
        
        const products = this.props.products.allMarkdownRemark.edges;
        
        // console.log(products);
        const settings = {
            focusOnSelect: true,
            dots: true,
            infinite: true,
            speed: 600,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 2,
            responsive: [
                {
                    breakpoint: 1024,
                    settings:{
                        dots: true,
                        infinite: true,
                        centerMode: false,
                        speed: 500,
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 600,
                    settings:{
                        dots: true,
                        infinite: true,
                        speed: 500,
                        centerMode: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        }

        return(
            <div className="prodbg">

                <Container className="pb-5 prodbg">
                    <Row className="pt-4 pb-3">
                        <Col sm={12} className="text-center text-white" data-aos="fade-up"  >
                            <h2 className="heading d-none d-md-block" >Products</h2>
                            <h2 className="heading d-sm-block d-md-none" >Products</h2>
                            <hr className="mb-5 bar-mobile-s" />
                        </Col>
                        <Col sm={12}>
                        <Slider {...settings} className="mx-3">

                            {products.map(
                                ({
                                    node: {
                                        id, frontmatter: { title, thumbnail, desc, subcategories, types, titletf, buttontf, desctf }
                                    }
                                })=>{
                                    if(titletf !== true && buttontf !== true && desctf !== true){
                                        if(subcategories === true && types && types.length > 0){
                                        return(
                                            <React.Fragment key={id}>
                                                <ProductWithPopup id={id} title={title} desc={desc} thumbnail={thumbnail} data={types} />
                                            </React.Fragment>
                                        )
                                        }
                                        else{
                                            return(
                                                <React.Fragment key={id}>
                                                    <ProductCard id={id} title={title} thumbnail={thumbnail} desc={desc} />
                                                </React.Fragment>
                                            ) 
                                        }
                                    }
                                    else {
                                        return null;
                                    }
                                    
                                      
                                }
                            )}
                            

                        </Slider>

                       
                        
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}



export default Products;