import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Parallax, Background } from 'react-parallax';
import aboutUs from '../images/AboutUs.jpg';


class AboutUs extends React.Component{
    
    render(){
        return(
             <section className="parallax">
                <Parallax strength={400} >
                    <Container className="mb-5">
                        <Row className="mt-4">
                            <Col sm={12} className="text-center text-white" data-aos="fade-up"  >
                                <h2 className="heading d-none d-md-block" >About Us</h2>
                                <h2 className="heading d-sm-block d-md-none"  >About Us</h2>
                                <hr className="mb-5 bar-mobile-s d-none d-md-block"  />
                                <hr className="mb-4 bar-mobile-s d-sm-block d-md-none" />
                            </Col>
                            
                            <Col md={6} className="text-center d-flex justify-content-center" >
                                <img 
                                    src="https://lh3.googleusercontent.com/vsxyQlkzAJFZyERXojVuFtmat_3g8KaQ-GBRYUZounFtUJU4kBOF3fz2YFI-dx0ESBbyzGoL8urV9bCRj2hhyZJXTibbxKXhG5Brv_sS22pqo_ZrfuOP9MTDFaozqQx3n1CmEu4T=w2400" 
                                    alt=""
                                    height="300"
                                    width="300"
                                    className="d-none d-md-block"
                                    data-aos="fade-right"
                                    />
                                <img 
                                    src="https://lh3.googleusercontent.com/vsxyQlkzAJFZyERXojVuFtmat_3g8KaQ-GBRYUZounFtUJU4kBOF3fz2YFI-dx0ESBbyzGoL8urV9bCRj2hhyZJXTibbxKXhG5Brv_sS22pqo_ZrfuOP9MTDFaozqQx3n1CmEu4T=w2400" 
                                    alt=""
                                    height="180"
                                    width="180"
                                    className="mb-4 d-sm-block d-md-none"
                                    data-aos="fade-right"
                                    />
                            </Col>
                            
                            <Col md={6}>
                                
                                
                                <p style={{fontSize: '18px'}} className="mt-4 text-white d-none d-md-block" data-aos="fade-left" data-aos-delay="200" >
                                    <span className="h4 color-secondary">Naqiyah Packaging Materials L.L.C.</span> <br/>
                                   has been a provider of products for packaging companies since 2015, specializing in the manufacture and trading of stretch film. 
                                    <br/><br/>
                                    We strive to provide responsibly sourced materials that are designed to be effective and safe throughout its life cycle. Our goal has always been, to meet our consumer’s choice and expectations while keeping in consideration the market criteria for performance and cost. Where possible, we try to provide products that can be recycled or recovered efficiently after use. 
                                    <br/><br/>
                                    We expertize in catering to all kinds of customer needs and providing the best service possible.</p>

                                {/* <h4 style={{color: '#a5ffd9'}} className="text-center d-sm-block d-md-none subhead">Random SubHeading</h4> */}

                                <p style={{fontSize: '13px'}} className="mx-4 mt-3 text-center text-white d-sm-block d-md-none " data-aos="fade-left" data-aos-delay="200">
                                    <span className="color-secondary font-weight-bold">Naqiyah Packaging Materials L.L.C.</span> has been a provider of products for packaging companies since 2015, specializing in the manufacture and trading of stretch film. 
                                    <br/><br/>
                                    We strive to provide responsibly sourced materials that are designed to be effective and safe throughout its life cycle. Our goal has always been, to meet our consumer’s choice and expectations while keeping in consideration the market criteria for performance and cost. Where possible, we try to provide products that can be recycled or recovered efficiently after use. 
                                    <br/><br/>
                                    We expertize in catering to all kinds of customer needs and providing the best service possible.
                                </p>
                            </Col>
                        
                        </Row>
                    </Container>  
                    <Background className="parallax-bg">
                        <img src={aboutUs} alt="about us"/>
                    </Background>
                </Parallax>
                                  
            </section>
        )
    }
}

export default AboutUs;