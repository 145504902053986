import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Parallax, Background } from 'react-parallax';
import Button from 'react-bootstrap/Button';
// import Prods from '../../content/products.json';
import contactUs from '../images/contact.png';



function encode(data) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }
class GetQuote extends React.Component{

    constructor(props) {
        super(props)
        this.state = { 
            show: false,
        }
    }

    handleClose = () => {
        this.setState({
            show: false
        })

        document.getElementById("product_form").reset();
    }

    handleOpen = () => {
        this.setState({
            show: true
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...this.state
                
            })
        })
        .then(()=>{this.handleOpen()})
        .catch((err)=>{console.log(err)})
    }


    
    render(){
        const products = this.props.products.allMarkdownRemark.edges;
        // console.log('products:',products);

        return(
            <>
                <Parallax strength={400}>
                    <Container className="mb-5">
                        <Row className="mt-4">
                            <Col sm={12} className="text-center color-primary" data-aos="fade-up" >
                                <h2 className="heading d-none d-md-block" >Get Quotation</h2>
                                <h2 className="heading d-sm-block d-md-none" >Get Quotation</h2>
                                <hr className="mb-4 bar-mobile-p " />
                            </Col>
                           
                        </Row>

                        <Modal show={this.state.show} onHide={this.handleClose}>
                            <Modal.Header closeButton>
                            <Modal.Title>Inquiry Submitted!</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Thank you for contacting us. The form has been submitted Successfully. We'll get back to you in sometime!</Modal.Body>
                            <Modal.Footer>
                            <Button variant="success" onClick={this.handleClose}>
                                Understood!
                            </Button>
                            </Modal.Footer>
                        </Modal>

                        <Form name="productinquiry" id="product_form" method="POST" data-netlify="true" action="#" onSubmit={this.handleSubmit}>
                            <Container fluid="md">
                                <Row>
                                    <Col sm={12} className="mb-3 text-center">
                                        <h4 className="color-primary font-weight-bold d-none d-md-block">Please choose the products you need a quotation for: </h4>
                                        <h5 className="color-primary font-weight-bold d-sm-block d-md-none">Please choose the products you need a quotation for: </h5>
                                    </Col>
                                    {products.map(
                                        ({
                                            node: {
                                                id, frontmatter: {title, titletf, buttontf, desctf}
                                            }
                                        })=>{
                                            if(titletf !== true && buttontf !== true && desctf !== true){
                                                return(
                                                    <Col xs={6} md={3} key={id}>
                                                        <Form.Group controlId={'product'+id} >
                                                            <Form.Check custom={true} type="checkbox" onChange={this.handleChange} label={title} size="6" className="custom-check" name={title} />
                                                        </Form.Group>
                                                    </Col>
                                                )
                                            }
                                            else{
                                                return null;
                                            }
                                                   
                                        }
                                    )}
                                    
                                </Row>
                                <Row className="mt-3">
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Control type="text" name="name" placeholder="Name" className="" onChange={this.handleChange} required />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Control type="email" name="email" placeholder="Email" className="" onChange={this.handleChange} required />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Control type="tel" name="phone" placeholder="Phone" className="" onChange={this.handleChange} required />
                                        </Form.Group>
                                    </Col>
                                        
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Control as="textarea" type="textarea" onChange={this.handleChange} name="message" placeholder="Message" className="full-height w-100" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mt-4 text-center">
                                    <Col sm={12}>
                                        <Button type="submit" size="lg" variant="primary" className="px-5 simple-btn">Submit</Button>
                                    </Col>
                                </Row>
                            </Container>
                            
                        </Form>
                    </Container>
                    <Background className="parallax-bg-secondary">
                        <img src={contactUs} alt="Contact us"/>
                    </Background>
                </Parallax>
            </>
        )
    }
}

export default GetQuote;
