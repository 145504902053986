import React from 'react';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../components/navbar';
import ImageCarousel from '../components/carousel';
import AboutUs from '../components/about';
import Features from '../components/features';
import Products from '../components/products';
import GetQuote from '../components/form';
import Footer from '../components/footer';
import Contact from '../components/contact';
import { graphql } from 'gatsby';
import AOS from 'aos';
import 'aos/dist/aos.css';



const Home = ({data}) => {

  React.useEffect(() => {
    AOS.init({
      duration : 800
    });
  }, []);
    
    return( 
    <div>
      <Helmet title="Naqiyah Pack">
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
          integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
          crossorigin=""
        />
        <script src="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"
          integrity="sha512-XQoYMqMTK8LvdxXYG3nZ448hOEQiglfqkJs1NOQV44cWnUrBc8PkAOcXy20w0vlaXaVUearIOBhiXZ5V3ynxwA=="
          crossorigin=""></script>
      </Helmet>
      
      <Header />
      <div id="home"></div>
      <ImageCarousel slides={data} />
      
      <div className="notice"></div>
      
      <div id="about">
        <AboutUs />
      </div>
        
      <Features />
      

      <div className="products" id="products">
        <Products products={data}/>
      </div>

      <div id="quote">
        <GetQuote products={data} />
      </div>

      <div id="contact">
        <Contact />
      </div>

      <div id="footer"  style={{'backgroundColor':'#002527'}}>
        <Footer />
      </div>
      
    </div> 
    )
}


export const query = graphql`

  query HomePageQuery{
    allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
          buttontf
          date
          desc
          desctf
          layout
          subcategories
          thumbnail
          title
          titletf
            types {
              subdesc
              subthumbnail
              subtitle
            }
          }
        }
      }
    }
  }
`;

export default Home;
