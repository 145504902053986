import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faShippingFast,
    faAward,
    faHandsHelping
 } from '@fortawesome/free-solid-svg-icons';


class Features extends React.Component{
    

    render(){
        return(
            <>
                <Container className="mt-4 mb-5">
                    <Row>
                        <Col sm={12} className="text-center" data-aos="fade-up"  >
                            <h2 className="heading d-none d-md-block color-primary font-weight-bolder" >Why Choose Us</h2>
                            <h2 className="heading d-sm-block d-md-none color-primary" >Why Choose Us</h2>
                            <hr className="mb-5 bar-mobile-p d-none d-md-block" />
                            <hr className="mb-4 bar-mobile-p d-sm-block d-md-none" />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} className="mt-3 text-center " data-aos="zoom-in" >
                            <FontAwesomeIcon icon={ faAward} size="3x" className="mb-2 color-primary " />
                            <h4 className="mt-2 color-primary font-weight-bold">Quality</h4>
                            <p className="mx-2 color-primary font-weight-normal">
                                We provide top notch quality products to fulfill your every need.  
                            </p>

                        </Col>
                        <Col md={4} className="mt-3 text-center " data-aos="zoom-in" >
                            <FontAwesomeIcon icon={ faHandsHelping } size="3x" className="mb-2 color-primary" />
                            <h4 className="mt-2 color-primary font-weight-bold">Reliability</h4>
                            <p className="mx-2 color-primary font-weight-normal">
                                Rely on our product quality and services and judge for yourself.
                            </p>
                        </Col>
                        <Col md={4} className="mt-3 text-center " data-aos="zoom-in" >
                            <FontAwesomeIcon icon={ faShippingFast } size="3x" className="mb-2 color-primary" />
                            <h4 className="mt-2 color-primary font-weight-bold">Service</h4>
                            <p className="mx-2 color-primary font-weight-normal">
                                We develop and provide our services to meet customer satisfaction.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default Features;
